import React from "react";

const WhatsAppButton = (props) => {
  const linkUrl = props.linkUrl;
    const messageTemplate = `Hello! I would like to inquire about the vehicle :\n → ${props.vehicleName}  \n → VIN:${props.vin} \n → LOT:${props.lot} \n → Price: ${props.price} ${props.currency} \n Could you please provide more details? Thank you! \n\n**********************\n Vehicle Link below : `;
    const encodedMessage = encodeURIComponent(messageTemplate); // Encode the message
    const encodedLinkUrl = encodeURIComponent(props.linkUrl);

   ; // Make sure the URL is publicly accessible
   const whatsappUrl = `https://api.whatsapp.com/send?phone=${props.phoneNumber}&text=${encodedMessage}%0A${encodedLinkUrl}`;

  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <img
        src={require('../../../assets/whatsapp_icon.png')} // Replace with your own icon if needed
        alt="WhatsApp Icon"
        style={{ width:props.height, height: props.width }}
      />
    </a>
  );
};

export default WhatsAppButton;
