export const currencies=[
    
        {"value": "USD", "label": "US Dollar"},
        {"value": "AED", "label": "UAE Dirham"},
        {"value": "EUR", "label": "Euro"},
        {"value": "GBP", "label": "British Pound Sterling"},
        {"value": "SAR", "label": "Saudi Riyal"},
        {"value": "INR", "label": "Indian Rupee"},
        {"value": "PKR", "label": "Pakistani Rupee"},
        {"value": "CNY", "label": "Chinese Yuan Renminbi"},
        {"value": "JPY", "label": "Japanese Yen"},
        {"value": "CAD", "label": "Canadian Dollar"},
        {"value": "AUD", "label": "Australian Dollar"},
        {"value": "NZD", "label": "New Zealand Dollar"},
        {"value": "CHF", "label": "Swiss Franc"},
        {"value": "ZAR", "label": "South African Rand"},
        {"value": "HKD", "label": "Hong Kong Dollar"},
        {"value": "SGD", "label": "Singapore Dollar"},
        {"value": "KRW", "label": "South Korean Won"},
        {"value": "MYR", "label": "Malaysian Ringgit"},
        {"value": "THB", "label": "Thai Baht"},
        {"value": "PHP", "label": "Philippine Peso"},
        {"value": "VND", "label": "Vietnamese Dong"},
        {"value": "NGN", "label": "Nigerian Naira"},
        {"value": "BRL", "label": "Brazilian Real"},
        {"value": "ARS", "label": "Argentine Peso"},
        {"value": "MXN", "label": "Mexican Peso"},
        {"value": "EGP", "label": "Egyptian Pound"},
        {"value": "RUB", "label": "Russian Ruble"},
        {"value": "TRY", "label": "Turkish Lira"},
        {"value": "SEK", "label": "Swedish Krona"},
        {"value": "NOK", "label": "Norwegian Krone"},
        {"value": "DKK", "label": "Danish Krone"},
        {"value": "PLN", "label": "Polish Zloty"},
        {"value": "HUF", "label": "Hungarian Forint"},
        {"value": "CZK", "label": "Czech Koruna"},
        {"value": "ISK", "label": "Icelandic Krona"},
        {"value": "ALL", "label": "Albanian Lek"},
        {"value": "BGN", "label": "Bulgarian Lev"},
        {"value": "MAD", "label": "Moroccan Dirham"},
        {"value": "KWD", "label": "Kuwaiti Dinar"},
        {"value": "BHD", "label": "Bahraini Dinar"},
        {"value": "OMR", "label": "Omani Rial"},
        {"value": "IQD", "label": "Iraqi Dinar"},
        {"value": "QAR", "label": "Qatari Riyal"},
        {"value": "AFN", "label": "Afghan Afghani"},
        {"value": "LKR", "label": "Sri Lankan Rupee"},
        {"value": "BDT", "label": "Bangladeshi Taka"},
        {"value": "IDR", "label": "Indonesian Rupiah"},
        {"value": "IRR", "label": "Iranian Rial"},
        {"value": "KES", "label": "Kenyan Shilling"},
        {"value": "ZMW", "label": "Zambian Kwacha"},
        {"value": "GHS", "label": "Ghanaian Cedi"},
        {"value": "TZS", "label": "Tanzanian Shilling"},
        {"value": "UGX", "label": "Ugandan Shilling"},
        {"value": "SYP", "label": "Syrian Pound"},
        {"value": "CLP", "label": "Chilean Peso"},
        {"value": "COP", "label": "Colombian Peso"},
        {"value": "BOB", "label": "Bolivian Boliviano"},
        {"value": "PYG", "label": "Paraguayan Guarani"},
        {"value": "UYU", "label": "Uruguayan Peso"},
        {"value": "DOP", "label": "Dominican Peso"},
        {"value": "JMD", "label": "Jamaican Dollar"},
        {"value": "TTD", "label": "Trinidad and Tobago Dollar"},
        {"value": "XAF", "label": "Central African CFA Franc"},
        {"value": "XOF", "label": "West African CFA Franc"},
        {"value": "MUR", "label": "Mauritian Rupee"},
        {"value": "SCR", "label": "Seychellois Rupee"},
        {"value": "NPR", "label": "Nepalese Rupee"},
        {"value": "MVR", "label": "Maldivian Rufiyaa"},
        {"value": "BND", "label": "Brunei Dollar"},
        {"value": "KZT", "label": "Kazakhstani Tenge"},
        {"value": "AZN", "label": "Azerbaijani Manat"},
        {"value": "AMD", "label": "Armenian Dram"},
        {"value": "GEL", "label": "Georgian Lari"},
        {"value": "UZS", "label": "Uzbekistani Som"},
        {"value": "TMT", "label": "Turkmenistani Manat"},
        {"value": "KGS", "label": "Kyrgyzstani Som"},
        {"value": "ETB", "label": "Ethiopian Birr"},
        {"value": "DJF", "label": "Djiboutian Franc"},
        {"value": "CDF", "label": "Congolese Franc"},
        {"value": "MWK", "label": "Malawian Kwacha"},
        {"value": "LSL", "label": "Lesotho Loti"},
        {"value": "NAD", "label": "Namibian Dollar"},
        {"value": "ANG", "label": "Netherlands Antillean Guilder"},
        {"value": "AWG", "label": "Aruban Florin"},
        {"value": "SRD", "label": "Surinamese Dollar"},
        {"value": "BWP", "label": "Botswana Pula"},
        {"value": "MGA", "label": "Malagasy Ariary"},
        {"value": "RWF", "label": "Rwandan Franc"},
        {"value": "SSP", "label": "South Sudanese Pound"},
        {"value": "VES", "label": "Venezuelan Bolívar Soberano"},
        {"value": "KYD", "label": "Cayman Islands Dollar"},
        {"value": "FJD", "label": "Fijian Dollar"},
        {"value": "TOP", "label": "Tongan Paʻanga"},
        {"value": "WST", "label": "Samoan Tala"},
        {"value": "PGK", "label": "Papua New Guinean Kina"},
        {"value": "SBD", "label": "Solomon Islands Dollar"},
        {"value": "BTN", "label": "Bhutanese Ngultrum"},
        {"value": "LAK", "label": "Lao Kip"},
        {"value": "MMK", "label": "Myanmar Kyat"},
        {"value": "KPW", "label": "North Korean Won"},
        {"value": "TJS", "label": "Tajikistani Somoni"},
        {"value": "MRU", "label": "Mauritanian Ouguiya"},
        {"value": "LYD", "label": "Libyan Dinar"},
        {"value": "GNF", "label": "Guinean Franc"},
        {"value": "SDG", "label": "Sudanese Pound"},
        {"value": "XPF", "label": "CFP Franc"},
        {"value": "LRD", "label": "Liberian Dollar"},
        {"value": "HTG", "label": "Haitian Gourde"},
        {"value": "BIF", "label": "Burundian Franc"},
        {"value": "MNT", "label": "Mongolian Tögrög"},
        {"value": "VUV", "label": "Vanuatu Vatu"},
        {"value": "XCD", "label": "East Caribbean Dollar"},
        {"value": "SZL", "label": "Eswatini Lilangeni"},
        {"value": "SHP", "label": "Saint Helena Pound"}
    
    
  ]
